import React, { PropsWithChildren } from "react";
import Facebook from "@mui/icons-material/Facebook";
import Instagram from "@mui/icons-material/Instagram";
import Twitter from "../../assests/Twitter.svg";
import YouTube from "@mui/icons-material/YouTube";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { SvgIcon } from "@mui/material";
import { FHInlineSvg } from "../FHInlineSvg";

const useStyles = (color: "white" | "orange") =>
  makeStyles((theme) => ({
    root: {
      border: "2px solid",
      padding: theme.spacing(1),
      color:
        color === "white"
          ? theme.palette.background.paper
          : theme.palette.secondary.main,
      borderColor:
        color === "white"
          ? theme.palette.background.paper
          : theme.palette.secondary.main,

      "&:not(:last-child)": {
        marginRight: theme.spacing(1),
      },
    },
    link: {
      "&:not(:last-child)": {
        marginRight: theme.spacing(1),
      },
    },
    iconPadding: { padding: theme.spacing(0.25) },
  }))();

export type SocialMediaOptions =
  | "Facebook"
  | "Instagram"
  | "Twitter"
  | "YouTube"
  | "TikTok";

export type SocialIconButtonProps = {
  type: SocialMediaOptions;
  alt?: string;
  className?: string;
  color?: "white" | "orange";
  url?: string;
  share?: boolean;
};

const MaterialIcon = ({ icon }: { icon: SocialMediaOptions }) => {
  switch (icon) {
    case "Facebook":
      return <Facebook />;
    case "Instagram":
      return <Instagram />;
    case "Twitter":
      return (
        <svg version="1.1">
          <path
            d="M14.2,10.2l8.6-10h-2l-7.4,8.6L7.4,0.2H0.5l9,13.1l-9,10.4h2l7.9-9.1l6.3,9.1h6.8L14.2,10.2L14.2,10.2z
	 M11.4,13.4l-0.9-1.3L3.3,1.8h3.1l5.8,8.4l0.9,1.3l7.6,10.9h-3.1L11.4,13.4L11.4,13.4z"
          />
        </svg>
      );
    case "TikTok":
      return (
        <svg version="1.1">
          <path
            id="XMLID_35_"
            d="M19.2,5.3c-1.3-0.8-2.3-2.3-2.5-3.8c0-0.3-0.1-0.7-0.1-1h-4v9.3v6.4c0,1.8-1.5,3.3-3.3,3.3
	c-0.6,0-1.1-0.1-1.6-0.4c-1-0.6-1.8-1.7-1.8-3c0-1.9,1.5-3.3,3.4-3.3c0.3,0,0.6,0,1,0.1V10V9.1C9.9,9,9.6,9,9.2,9
	c-4.1,0-7.3,3.2-7.3,7.3c0,2.4,1.2,4.6,3.1,5.9c1.2,0.8,2.6,1.3,4.2,1.3c4.1,0,7.3-3.3,7.3-7.3V8.1c1.5,1.3,3.5,2,5.5,1.9V7V6
	C21,6.2,20,5.9,19.2,5.3L19.2,5.3z"
          />
        </svg>
      );
    case "YouTube":
      return <YouTube />;
    default:
      return null;
  }
};

const makeUrl = (
  icon: SocialMediaOptions,
  url: string,
  makeShareUrl: boolean,
): string => {
  if (makeShareUrl) {
    switch (icon) {
      case "Facebook":
        return `https://www.facebook.com/sharer.php?u=${url}`;
      case "Twitter":
        return `https://twitter.com/intent/tweet?url=${url}`;
      default:
        return url;
    }
  }

  return url;
};

export const SocialIconButton: React.FC<SocialIconButtonProps> = ({
  type,
  alt,
  color = "white",
  url,
  share = false,
}: PropsWithChildren<SocialIconButtonProps>) => {
  const classes = useStyles(color);
  return (
    <>
      {url ? (
        <a
          data-testid="social-icon-anchor"
          href={makeUrl(type, url, share)}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
          aria-label={alt ? alt : type}
        >
          <IconButton
            data-testid="social-icon-button"
            classes={{
              root: classes.root,
            }}
            aria-label={alt ? alt : type}
          >
            {type === "Twitter" || type === "TikTok" ? (
              <SvgIcon className={classes.iconPadding}>
                <MaterialIcon icon={type} />
              </SvgIcon>
            ) : (
              <MaterialIcon icon={type} />
            )}
          </IconButton>
        </a>
      ) : (
        <IconButton
          data-testid="social-icon-button"
          className={classes.root}
          aria-label={alt ? alt : ""}
        >
          {type === "Twitter" || type === "TikTok" ? (
            <SvgIcon className={classes.iconPadding}>
              <MaterialIcon icon={type} />
            </SvgIcon>
          ) : (
            <MaterialIcon icon={type} />
          )}
        </IconButton>
      )}
    </>
  );
};
